import { Link, useLocation } from "react-router-dom"
import logo from "../assets/images/avatar1.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faBars, faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState, useRef } from "react"
import constants from "../constants/constants"
export default function Header() {
    const [menus, setMenus] = useState({ buying: false, selling: false, info: false })
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [headerPosition, setHeaderPosition] = useState(-100)
    const [path, setPath] = useState(window.location.href.split('#')[1]);
    const [headerTiming, setHeaderTiming] = useState(.8)
    const location = useLocation();




    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    function setDropdown(key, bool) {
        var temp = { ...menus }
        for (let k of Object.keys(temp)) {
         
            if (key === k) {
                temp[k] = bool;
            } else {
                temp[k] = false;
            }
        }

      
        setMenus(temp)
    }

    function navClick() {
        setHeaderPosition(-100);
        setHeaderTiming(0);
        setTimeout(() => { setHeaderTiming(.8) }, 100);
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'auto';
        }
    }
    useEffect(() => {
        setPath(window.location.href.split('#')[1])
    }, [location])

    // const currentPathRef = useRef(window.location.pathname);

    // if (location.pathname !== currentPathRef.current) {
    //     window.scrollTo(0, 0);
    //     currentPathRef.current = location.pathname;
    // }


    return (
        <>
        {windowSize > 950 ?
        <div style={{ gridTemplateColumns: "1fr auto 1fr" }} className="bg-white w-full py-4 px-8 grid min-h-28 items-center">
            <img src={constants.LOGO} className="w-24" />
            <div className="flex gap-x-10 justify-center font-semibold child:relative mt-2">
                <Link to={'/'}>Home</Link>
                <Link to={'/about'}>About</Link>
                <div className="cursor-pointer pb-2" onMouseEnter={()=>setDropdown('buying', true)} onMouseLeave={()=>setDropdown('buying', false)} >Buying <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.buying ? 'fa-flip-vertical' : ''}`} />
                {menus.buying &&
                    <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                        <Link className="hover:bg-logo-second hover:text-white" to={'/calculator'}>Mortgage Calculator</Link>
                        <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Listings</Link>

                    </div>}
                </div> 
                <div className="cursor-pointer" onMouseEnter={()=>setDropdown('selling', true)} onMouseLeave={()=>setDropdown('selling', false)} >Selling <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.selling ? 'fa-flip-vertical' : ''}`} />
                {menus.selling &&
                    <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                        <Link className="hover:bg-logo-second hover:text-white" to={'/evaluation'}>Free Home Evaluation</Link>

                    </div>}
                </div>
                <div className="cursor-pointer" onMouseEnter={()=>setDropdown('info', true)} onMouseLeave={()=>setDropdown('info', false)} >Information <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.info ? 'fa-flip-vertical' : ''}`} />
                {menus.info &&
                    <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                        <Link className="hover:bg-logo-second hover:text-white" to={'/real-estate-reports'}>Real Estate Reports</Link>

                    </div>}
                </div>
                <Link to={'/contact'}>Contact</Link>


            </div>

        </div> :
         <>
         <div className="relative w-full h-32 z-30 bg-light-grey grid sm:grid-cols-3 grid-cols-[1fr_50%_1fr] items-center">
             <div className="z-20 transition-opacity duration-700 ease-in-out w-full h-full absolute bg-white " />
             {headerPosition === 0 ?
                 <FontAwesomeIcon onClick={() => { setHeaderPosition(-100); if (typeof window != 'undefined' && window.document) { document.body.style.overflow = 'auto';} }} className="z-30 text-2xl px-4 text-mb-red cursor-pointer" icon={faXmark} />
                 :
                 <FontAwesomeIcon onClick={() => { setHeaderPosition(0); if (typeof window != 'undefined' && window.document) {document.body.style.overflow = 'hidden';} }} className="z-30 text-2xl px-4 text-mb-red cursor-pointer" icon={faBars} />
             }
             <div className="flex justify-center">
                 <img src={logo} className="z-30 h-24"></img>
             </div>

         </div>
         <nav style={{ transition: `translate ${headerTiming}s cubic-bezier(0, .52, 0, 1)`, translate: `${headerPosition}vw`, }} className={`flex flex-col gap-12 px-6 py-9 bg-[#f9f9f9] fixed z-20 w-[75%] h-full translate-x-[${headerPosition}vw] child:tracking-wider child:font-bold`}>
             <Link onClick={() => { navClick() }} className={` ${path === "/" ? 'active-page' : ''}`} to={'/'}>Home {path !== '/' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/about" ? 'active-page' : ''}`} to={'/about'}>About {path !== '/about' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/calculator" ? 'active-page' : ''}`} to={'/calculator'}>Mortgage Calculator {path !== '/calculator' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/listings" ? 'active-page' : ''}`} to={'/listings'}>Listings {path !== '/listings' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/evaluation" ? 'active-page' : ''}`} to={'/evaluation'}>Free Home Evaluation {path !== '/evaluation' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/real-estate-reports" ? 'active-page' : ''}`} to={'/real-estate-reports'}>Free Real Estate Reports {path !== '/real-estate-reports' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
             <Link onClick={() => { navClick() }} className={` ${path === "/contact" ? 'active-page' : ''}`} to={'/contact'}>Contact {path !== '/contact' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>

         </nav>

     </>
        }
        </>
    )
}