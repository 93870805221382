import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import ReactImageGallery from "react-image-gallery";
import imgs from "./temp.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBath, faBed, faClock, faEnvelope, faPhone, faRulerCombined } from "@fortawesome/free-solid-svg-icons";
import professional from '../assets/images/Smiling_Professional-min.jpg'
import constants from '../constants/constants'
import placeholder from "../assets/images/house-placeholder.webp"


export default function Listing() {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/"
    const [popup, setPopup] = useState(false)
    async function submitForm(event) {
        event.preventDefault();
        const form = document.getElementById('form');

        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json()).catch(err => {
            console.log(err);
        });

        if (res?.success) {
            console.log("Success", res);
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })

        } else {
            setPopup({ subject: 'We encountered an error in sending this message', message: 'Please try again later' })
        }

    }
    const { id } = useParams()
    const [property, setProperty] = useState({})
    const photos = useRef([])
    const [media, setMedia] = useState(imgs.full.value)
    const [formatted, setFormatted] = useState({})
    const loaded = useRef(false)
    const [defaultMessage, setDefaultMessage] = useState(`Hello, I recently came across a listing and would like more information.`)
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    function formatStr(str) {

        const result = str
            .replaceAll(' ', '(S)')
            .replaceAll('&', '(A)')
            .replaceAll('?', '(Q)');

            return result

    }


    function getData() {
        var body = {

            "url": `https://query.ampre.ca/odata/Property?$filter=ListingKey eq '${id}'`,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }
        return new Promise(async (resolve, reject) => {
            try {
                // let request = `odata/Property?$filter=ListingKey eq '${id}'`
                let request = `odata/Property(Q)$filter=ListingKey(S)eq(S)'${id}'`
                let data = constants.ENVRIONMENT === 'prod' ? await fetch(`https://uvhomes.aptoconnect.com/api/${request}`)
                : await fetch(`http://${constants.URL}/metaGet`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json"
                    }

                })
                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }
    

    function formatMedia(media) {
        if (!media?.length) {
            return Array(6).fill({original: placeholder, thumbnail: placeholder })
        }
        var temp1 = {}

        for (let img of media) {

                if (temp1[img.MediaObjectID]) {
                    temp1[img.MediaObjectID] = { ...temp1[img.MediaObjectID], [img.ImageSizeDescription]: img }
                } else {
                    
                    temp1[img.MediaObjectID] = { [img.ImageSizeDescription]: img }

                }
            
        }
        var temp = []
        for (let [k, v] of Object.entries(temp1)) {

            temp.push({ original: v?.LargestNoWatermark?.MediaURL, thumbnail: v?.Thumbnail?.MediaURL })

        }

        return temp;
    }

    function getMedia() {
        var body = {

            "url": `https://query.ampre.ca/odata/Media?$filter=ResourceRecordKey eq '${id}' and (ImageSizeDescription eq 'LargestNoWatermark' or ImageSizeDescription eq 'Thumbnail')`,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }
        return new Promise(async (resolve, reject) => {
            try {
                let request = `odata/Media?$filter=ResourceRecordKey eq '${id}' and (ImageSizeDescription eq 'LargestNoWatermark' or ImageSizeDescription eq 'Thumbnail')`
                let data = constants.ENVRIONMENT === 'prod' ? await fetch(formatStr(`https://uvhomes.aptoconnect.com/api/${request}`))
                : await fetch(`http://${constants.URL}/metaGet`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json"
                    }

                })
                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    useEffect(() => {
        if (!loaded.current) {
            getData().then(data => {
                setProperty(data.value[0])
                setDefaultMessage(`Hello, I recently came across a listing at ${data.value[0]?.StreetNumber ?? '--'} ${data.value[0]?.StreetName ?? '--'} ${data.value[0]?.StreetSuffix ?? ''} and would like more information.`)


            }).catch(err => {
                console.log(err);
            })
            getMedia().then(data => {
                photos.current = data.value
                setImages(formatMedia(data.value))


            }).catch(err => {
                console.log(err);
            })

            loaded.current = true;

        }



    }, [])
    const [images, setImages] = useState([])

    // useEffect(() => {
    //     var temp = []
    //     for (let img of Object.values(formatted)) {
    //         for (let [k, v] of Object.entries(img)) {
    //             temp.push({ original: v?.LargestNoWatermark?.MediaURL, thumbnail: v?.Thumbnail?.MediaURL })

    //         }


    //     }
    //     setImages(temp)
    //     console.log(temp)
    // }, [formatted])

    const [detailsOpen, setDetailsOpen] = useState(false)


    return (
        <>
            
            <div className="max-w-[1300px] mx-auto px-8 sm:w-[75vw]">
                <div className="flex justify-between pt-8 pb-6 mt-6 items-end gap-x-10 gap-y-6 flex-wrap">
                    <div>
                        <h1 className="text-4xl text-black font-mont ">{property?.StreetNumber} {property?.StreetName} {property?.StreetSuffix}</h1>
                        <p className="text-med-grey text-2xl mt-2">{property?.City} {property?.StateOrProvince}, {property?.PostalCode}</p>
                    </div>
                    <div className="flex flex-col lg:items-end whitespace-nowrap">
                        <h1 className="font-semibold text-xl text-black"><span className="text-blue-700">Listing Price:</span> {property?.ListPrice?.toLocaleString()}</h1>
                        <p className="font-semibold mt-2 text-med-grey text-lg"> <FontAwesomeIcon className="text-blue-800 mr-1" icon={faClock} /> Listed on {property?.OriginalEntryTimestamp ? new Date(property?.OriginalEntryTimestamp).toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' }) : '--'}</p>
                    </div>
                </div>
                <div className="flex gap-x-8 justify-between h-fit">
                    <div className="min-[1201px]:w-[80%]">
                        <ReactImageGallery items={images} loading={'eager'} thumbnailPosition={`${windowSize > 950 ? 'right' : 'bottom'}`} showThumbnails={windowSize > 950 ? true : false} showPlayButton={false} />
                        <div className="w-full px-3 py-2 bg-logo-second mt-5">
                            <span className="text-white font-semibold ">Property Features</span>

                        </div>
                        <div className="grid mt-5 gap-x-10 gap-y-5 w-[75%] sm:grid-cols-2">
                            <div className="flex gap-x-3 items-end">
                                <FontAwesomeIcon icon={faBed} className="text-logo-second fa-2xl" />
                                <span className="font-mont text-xl font-semibold text-med-grey">{property?.BedroomsTotal ? property?.BedroomsTotal + ' Bedrooms' : '--'}</span>
                            </div>
                            <div className="flex gap-x-3 items-end">
                                <FontAwesomeIcon icon={faBath} className="text-logo-second fa-2xl" />
                                <span className="font-mont text-xl font-semibold text-med-grey">{property?.BathroomsTotalInteger ? property?.BathroomsTotalInteger + ' Bathrooms' : '--'}</span>
                            </div>
                            <div className="flex gap-x-3 items-end">
                                <FontAwesomeIcon icon={faRulerCombined} className="text-logo-second fa-2xl" />
                                <span className="font-mont text-xl font-semibold text-med-grey">{property?.LivingAreaRange ? property?.LivingAreaRange + ' Sq Ft' : property?.OfficeApartmentArea ? property?.OfficeApartmentArea + 'Sq Ft' : '--'}</span>
                            </div>

                        </div>



                        <h1 className="text-4xl text-black font-mont mt-12">About This Property</h1>
                        <p className={`mt-6 overflow-hidden ${!detailsOpen && property?.PublicRemarks?.length > 750 ? 'masked max-h-44' : ''}`}>{property?.PublicRemarks}</p>
                        {property?.PublicRemarks?.length > 750 && !detailsOpen ?
                            <button onClick={() => setDetailsOpen(!detailsOpen)} className="bg-light-grey text-med-grey hover:shadow-md hover:text-black duration-point-three rounded-lg px-3 py-1.5 cursor-pointer shadow-sm mx-auto mt-3 w-fit block">Show {detailsOpen ? 'Less' : 'More'} <FontAwesomeIcon transform={{ rotate: detailsOpen ? 180 : 0 }} icon={faAngleDown} /></button>
                            : null}
                        {/* {property?.PublicRemarksExtras ? */}

                        <h1 className="text-4xl text-black font-mont mt-12">Additional Info</h1>

                        <div className="grid md:grid-cols-2 mt-5 gap-x-10 gap-y-5">
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Property Type: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.PropertyType ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Sub-Type: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.PropertySubType ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Heating: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.HeatSource ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">HVAC: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.HeatType ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Garage: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.GarageType ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Parking: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.ParkingTotal ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Exposure: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.DirectionFaces ?? '--'}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Lot: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.LotSizeRangeAcres ?? '--'} {property?.LotSizeUnits}</span>
                            </div>
                            <div className="flex gap-x-3 ">
                                <span className="text-black text-lg font-mont font-semibold">Basement: </span>
                                <span className="font-mont text-lg text-med-grey">{property?.Basement?.length ? property.Basement.join(', ') : '--'}</span>
                            </div>
                        </div>
                        <p className="mt-6">{property?.PublicRemarksExtras}</p>

                        <h1 className="text-4xl text-black font-mont mt-12">Affordability Calculator</h1>

                        <iframe className="mb-10 md:-ml-[4.5rem] -ml-[1rem] sm:-ml-[3rem] min-[1109px]:-ml-[3rem] min-[1430px]:-ml-[6rem] min-[1552px]:-ml-[4rem] min-[1710px]:-ml-[7rem] mt-6" src={`https://www.mortgagecalculator.org/webmasters/?downpayment=80000&homevalue=${property?.ListPrice}&loanamount=320000&interestrate=7&loanterm=30&propertytax=2700&pmi=1&homeinsurance=1500&monthlyhoa=0`} scrolling='no' style={{ width: '100%', height: windowSize > 852 ? '1200px' : '2300px', border: 0, }}></iframe>


                    </div>

                    <div className="sticky top-4 min-w-[300px] h-fit max-[1200px]:hidden mb-10" >
                        <div className="bg-light-grey rounded-lg py-6 px-4 h-fit  ">
                            <h4 className="font-mont font-semibold text-black text-lg text-center">Presented By</h4>
                            <div style={{ overflowClipMargin: 'unset' }} className="rounded-full w-44 h-44 overflow-clip mx-auto my-6">
                                <img className='w-full h-full object-cover' src={professional} />
                            </div>
                            <div className="flex flex-col gap-y-2 mx-auto w-fit text-black">
                                <h2 className="text-black text-2xl">{constants.AGENT_NAME}</h2>
                                <p className="italic">{constants.AGENT_TITLE}, {constants.BROKERAGE}</p>
                                <p><FontAwesomeIcon icon={faPhone} className="text-blue-800 fa-lg" /> {constants.AGENT_NUMBER} </p>
                                <p><FontAwesomeIcon icon={faEnvelope} className="text-blue-800 fa-lg" /> {constants.AGENT_EMAIL} </p>
                            </div>
                        </div>
                        <div className="bg-light-grey rounded-lg py-6 px-4 h-fit mt-4 ">
                            <h4 className="font-mont font-semibold text-black text-lg text-center">Inquire About This Listing</h4>
                            <form id="form" onSubmit={submitForm} className="mt-6 px-3 flex flex-col">
                            <input type="hidden" name="access_key" value={constants.EMAIL_ACCESS_KEY} />
                            <input type="hidden" name="subject" value="New Inquiry About a Listing" />
                                <div className="form-item">
                                    <label className="form-label">Name*</label>
                                    <input className="form-input" name="Name" placeholder="Full Name"></input>
                                </div>
                                <div className="form-item">
                                    <label className="form-label">Email*</label>
                                    <input className="form-input" name="Email Address" placeholder="Email Address"></input>
                                </div>
                                <div className="form-item">
                                    <label className="form-label">Message*</label>
                                    <textarea className="form-input resize-none h-32" name="Message" onChange={(e) => setDefaultMessage(e.target.value)} value={defaultMessage}></textarea>
                                </div>
                                <button type="submit" className="btn-primary self-end">Send</button>
                            </form>
                        </div>


                    </div>

                </div>
            </div>
            {popup &&
                <>
                    <div className="backdrop" />
                    <div className="popup">
                        <h5 className="font-bold text-black text-lg">{popup.subject}</h5>
                        <p className="font-semibold text-med-grey tracking-wide mb-3">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}

        </>
    )
}