import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import constants from '../constants/constants'

export default function About() {
    return (
        <div className="max-w-[1100px] mx-auto gap-x-24 gap-y-12 px-6 grid sm:grid-cols-2 my-12 min-h-[70vh]">
            <div>
                <img src={constants.HEADSHOT} className='w-full'/>
                <div className="flex flex-col gap-y-2 text-black mt-6">
                        <h2 className="text-black text-3xl">{constants.AGENT_NAME}</h2>
                        <p className="italic text-lg">{constants.AGENT_TITLE}, {constants.BROKERAGE}</p>

                        <div className='flex gap-x-5 my-2'>
                            {Object.entries(constants.SOCIALS)?.map(([k, v])=>
                            <a href={constants.SOCIALS[k]}><FontAwesomeIcon className='fa-xl text-logo-second' icon={constants.ICONS[k]}/></a>
                            )}
                        </div>
                        <p className='font-semibold text-lg'>{constants.AGENT_NUMBER} </p>
                        <p className='text-lg'>{constants.AGENT_EMAIL} </p>
                    </div>
                   

            </div>
            <div>
            <h1 className="text-4xl text-black pb-8 font-mont ">ABOUT ME</h1>

            <p>{constants.ABOUT}</p>
            </div>

        </div>
    )
}