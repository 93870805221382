export default function Calculator() {
    return (
        <>
            <div className="container py-12">
                <h1 className="text-black font-mont text-4xl pb-4">MORTGAGE CALCULATOR</h1>
                <span className="font-mont text-med-grey font-semibold text-lg">Find a price range that works with your budget by using our Mortgage Calculator</span>
            </div>
            <iframe src="https://www.mortgagecalculator.org/webmasters/?downpayment=80000&homevalue=400000&loanamount=320000&interestrate=7&loanterm=30&propertytax=2700&pmi=1&homeinsurance=1500&monthlyhoa=0" scrolling='no' style={{ width: '100%', height: '1200px', border: 0 }}></iframe>
            {/* <div style={{ fontFamily: 'Arial', height: '36px', top: '-36px', padding: '0 8px 0 0', boxSizing: 'border-box', textAlign: 'right', background: '#f6f9f9', border: '1px solid #ccc', color: '#868686', lineHeight: '34px', fontSize: '12px', position: 'relative' }}>
                </div> */}


        </>
    )
}