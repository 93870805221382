import logo from '../assets/images/avatar1.png'
import headshot from '../assets/images/Smiling_Professional-min.jpg'
import { faFacebook, faInstagram, faTwitter, faYoutube, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons'
const constants = {
    AGENT_NAME: 'Nick Lastname',
    AGENT_TITLE: 'Broker',
    AGENT_NUMBER: '123 456-7890',
    AGENT_EMAIL: 'nicklastname@gmail.com',
    BROKERAGE: 'Remax Reality',
    SOCIALS: {
        'twitter': 'https://www.x.com',
        'facebook': 'https://www.x.com',
        'instagram': 'https://www.x.com',
        'youtube': 'sfsfsf',
        'linkedIn': 'fssfsf',
        'tikTok': 'sfsfsf'
    },
    ICONS: { twitter: faTwitter, facebook: faFacebook, instagram: faInstagram, youtube: faYoutube, linkedIn: faLinkedin, tikTok: faTiktok },
    LOGO: logo,
    HEADSHOT: headshot,
    AGENT_ID: '3425022',
    OFFICE_ID: '347200',
    // TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMyMDM1MjYwLCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiNWRjYWMyMzI5ZDZiZDllMiIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.hi1h3xh8cJnCbq0kyrH3PnGZD1mR08FXd4T3Lx2xiy8',
    TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4',
    URL: '67.223.102.95:3000',
    ENVRIONMENT: 'local',
    // ENVRIONMENT: 'prod',
    EMAIL_ACCESS_KEY: 'ce242f9a-5573-49b8-b802-84368d601c83',
    ABOUT: 'about me text'

}

export default constants;