import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import constants from "../constants/constants";

export default function Footer() {
    const [menus, setMenus] = useState({ buying: false, selling: false, info: false })
    function setDropdown(key) {
        var temp = { ...menus }
        for (let k of Object.keys(temp)) {

            if (key === k) {
                temp[k] = !temp[k];
            } else {
                temp[k] = false;
            }
        }


        setMenus(temp)
    }
    return (
        <div className="bg-logo-second w-full py-12 px-16 flex flex-wrap gap-x-20 gap-y-10 justify-between " >
            <div className="grid md:grid-cols-2 gap-x-24 w-fit gap-y-3 font-semibold child:relative mt-2 text-white">
                <div className="flex flex-col gap-y-3">
                    <Link to={'/'}>Home</Link>
                    <Link to={'/about'}>About</Link>
                    <Link to={'/contact'}>Contact</Link>

                </div>
                <div className="flex flex-col gap-y-3">
                    <div className="cursor-pointer" onClick={() => setDropdown('buying')}  >Buying <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.buying ? 'fa-flip-vertical' : ''}`} />
                        {menus.buying &&
                            <div className="text-black absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                <Link className="hover:bg-logo-second hover:text-white" to={'/calculator'}>Mortgage Calculator</Link>
                                <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Listings</Link>

                            </div>}
                    </div>
                    <div className="cursor-pointer" onClick={() => setDropdown('selling')}  >Selling <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.selling ? 'fa-flip-vertical' : ''}`} />
                        {menus.selling &&
                            <div className="text-black absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                <Link className="hover:bg-logo-second hover:text-white" to={'/evaluation'}>Free Home Evaluation</Link>

                            </div>}
                    </div>

                    <div className="cursor-pointer" onClick={() => setDropdown('info')}  >Information <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.info ? 'fa-flip-vertical' : ''}`} />
                    {menus.info &&
                    <div className="text-black top-3 absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                        <Link className="hover:bg-logo-second hover:text-white" to={'/real-estate-reports'}>Real Estate Reports</Link>

                    </div>}
                    </div>

                </div>


            </div>
            <div className="flex flex-col items-end">
            <h1 className="text-2xl text-white pb-8 font-mont ">FOLLOW</h1>
            <div className='flex gap-x-5 my-2'>
                            {Object.entries(constants.SOCIALS)?.map(([k, v])=>
                            <a href={constants.SOCIALS[k]}><FontAwesomeIcon className='fa-xl text-white' icon={constants.ICONS[k]}/></a>
                            )}
                        </div>

            </div>

        </div>
    )
}