import house from '../assets/images/stock-photo-modern-minimalist-two-story-house-with-a-flat-roof-and-large-glass-windows-featuring-a-sleek-2493365641.jpg'
import constants from '../constants/constants';
import { useState } from 'react';
export default function Evaluation() {
    const [popup, setPopup] = useState(false)
    async function submitForm(event) {
        event.preventDefault();
        const form = document.getElementById('form');

        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json()).catch(err => {
            console.log(err);
        });

        if (res?.success) {
            console.log("Success", res);
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })

        } else {
            setPopup({ subject: 'We encountered an error in sending this message', message: 'Please try again later' })
        }

    }
    function submitForm(event) {
        event.preventDefault();

    }
    return (
        <>
        <div className="max-w-[1100px] mx-auto grid md:grid-cols-2 px-6 gap-x-24 gap-y-10 min-h-[70vh] my-12">
            <div>
                <h1 className="text-4xl text-black font-mont ">FREE HOME EVALUATION</h1>

                <p className='font-semibold text-med-grey my-5'>Wondering how much your home would sell for in today's market? Please complete the form below and I would be happy to send you a Free Home Evaluation.</p>
                <form id="form" onSubmit={submitForm}>
                <input type="hidden" name="access_key" value={constants.EMAIL_ACCESS_KEY} />
                <input type="hidden" name="subject" value="New Lead Interested in Real Estate Reports" />

                    <div className="form-item">
                        <label className="form-label">Name*</label>
                        <input required className="form-input" placeholder="Full Name"></input>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Email*</label>
                        <input required className="form-input" placeholder="Email Address"></input>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Phone*</label>
                        <input required className="form-input" placeholder="Phone Number"></input>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Property Address*</label>
                        <input required className="form-input" placeholder="Address of the Property"></input>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-10">
                        <div className="form-item">
                            <label className="form-label">City*</label>
                            <input required className="form-input" placeholder="City of the Property"></input>
                        </div>

                        <div className="form-item">
                            <label className="form-label">Province/State*</label>
                            <input required className="form-input" placeholder="Province/State of the Property"></input>
                        </div>

                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-10">

                        <div className="form-item">
                            <label className="form-label">Property Size*</label>
                            <input required className="form-input" placeholder="Approx. Square Footage"></input>
                        </div>
                        <div className="form-item">
                            <label className="form-label">Lot Size*</label>
                            <input required className="form-input" placeholder="Approx. Square Footage/Acreage"></input>
                        </div>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Property Type*</label>
                        <select required className="form-input" >
                            <option value={''}>Please Select</option>
                            <option value={'Residential'}>Residential</option>
                            <option value={'Commercial'}>Commercial</option>
                            <option value={'Land'}>Land</option>
                            <option value={'Parking'}>Parking</option>
                            <option value={'Investment'}>Investment</option>
                            <option value={'Agricultural'}>Agricultural</option>
                        </select>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Building Type*</label>
                        <select required className="form-input" >
                            <option value={''}>Please Select</option>
                            <option value={'House'}>House</option>
                            <option value={'Townhouse'}>Townhouse</option>
                            <option value={'Condo'}>Condo</option>
                            <option value={'Duplex'}>Duplex</option>
                            <option value={'Triplex'}>Triplex</option>
                            <option value={'Office'}>Office</option>
                            <option value={'Mobile Home'}>Mobile Home</option>
                            <option value={'Retail'}>Retail</option>
                        </select>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Other Notes</label>
                        <textarea className="form-input resize-none h-32" placeholder="Please tell me any other information you feel I should know about your property"></textarea>
                    </div>
                    <button type='submit' className="btn-primary self-start rounded-none px-10 mt-3">Submit</button>



                </form>
            </div>
            <div className="overflow-hidden h-[600px] ">
                <img className="h-full w-full object-cover" src={house} />
            </div>

        </div>
        {popup &&
                <>
                    <div className="backdrop" />
                    <div className="popup">
                        <h5 className="font-bold text-black text-lg">{popup.subject}</h5>
                        <p className="font-semibold text-med-grey tracking-wide mb-3">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}
        </>
    )
}