import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import constants from "../constants/constants"
import { useEffect, useState } from "react";
export default function Contact() {
    const [popup, setPopup] = useState(false)
    async function submitForm(event) {
        event.preventDefault();
        const form = document.getElementById('form');

        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json()).catch(err => {
            console.log(err);
        });

        if (res?.success) {
            console.log("Success", res);
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })

        } else {
            setPopup({ subject: 'We encountered an error in sending this message', message: 'Please try again later' })
        }

    }
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    return (
        <>
        <div className="w-full bg-light-grey flex flex-wrap gap-x-16 gap-y-10 justify-center py-16 px-7 min-h-[70vh]">
                <div>
                    <h4 className="font-mont font-semibold text-black text-lg min-w-[20vw]">Questions? I'm Here To Help</h4>
                    <form id="form" onSubmit={submitForm} className="mt-6 flex flex-col">
                    <input type="hidden" name="access_key" value={constants.EMAIL_ACCESS_KEY} />
                    <input type="hidden" name="subject" value="New Contact Form Message" />
                        <div className="form-item">
                            <label className="form-label">Name*</label>
                            <input className="form-input" name="Name" placeholder="Full Name"></input>
                        </div>
                        <div className="form-item">
                            <label className="form-label">Email*</label>
                            <input className="form-input" name="Email Address" placeholder="Email Address"></input>
                        </div>
                        <div className="form-item">
                            <label className="form-label">Message*</label>
                            <textarea className="form-input resize-none h-32" name="Message" placeholder="Your Message"></textarea>
                        </div>
                        <button type="submit" className="btn-primary self-start rounded-none px-10">Send</button>
                    </form>
                </div>
                <div>
                    <iframe class="contact-map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.1670922064877!2d-79.3108446!3d43.686289499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cc14ec855631%3A0xb3d0feb68a2d0ed3!2s85%20Cedarvale%20Ave%2C%20Toronto%2C%20ON%20M4C%204J7!5e0!3m2!1sen!2sca!4v1730912488107!5m2!1sen!2sca"
                        height="365" width={`${windowSize > 900 ? '500' : '300'}`} style={{ border: 0 }} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>

                    <div className="flex flex-col gap-y-2 text-black mt-6">
                        <h2 className="text-black text-2xl">{constants.AGENT_NAME}</h2>
                        <p className="italic">{constants.AGENT_TITLE}, {constants.BROKERAGE}</p>
                        <p><FontAwesomeIcon icon={faPhone} className="text-blue-800 fa-lg" /> {constants.AGENT_NUMBER} </p>
                        <p><FontAwesomeIcon icon={faEnvelope} className="text-blue-800 fa-lg" /> {constants.AGENT_EMAIL} </p>
                    </div>
                </div>

            </div>
            {popup &&
                <>
                    <div className="backdrop" />
                    <div className="popup">
                        <h5 className="font-bold text-black text-lg">{popup.subject}</h5>
                        <p className="font-semibold text-med-grey tracking-wide mb-3">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}
            </>
    )
}