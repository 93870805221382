import img from '../assets/images/stock-photo-businesswoman-holding-smartphone-while-working-with-laptop-at-home-office-desk-using-cross-699442570.jpg'
import { useState } from 'react';
import constants from '../constants/constants';
export default function Reports() {
    const [popup, setPopup] = useState(false)

    async function submitForm(event) {
        event.preventDefault();
        const form = document.getElementById('form');

        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json()).catch(err => {
            console.log(err);
        });

        if (res?.success) {
            console.log("Success", res);
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })

        } else {
            setPopup({ subject: 'We encountered an error in sending this message', message: 'Please try again later' })
        }

    }
    return (
        <>
        <div className="max-w-[1100px] mx-auto grid md:grid-cols-2 px-6 gap-x-24 gap-y-10 min-h-[70vh] my-12">
            <div>
                <h1 className="text-4xl text-black font-mont ">FREE REAL ESTATE REPORTS</h1>

                <p className='font-semibold text-med-grey my-5'>Get exclusive buyer & seller real estate reports to help you with your next purchase or sale. Get insider tips and tricks, and learn how to invest your time and money wisely in the real estate market. Just complete the form below, select all the reports you'd like to receive and have them emailed to your inbox right away. Get started now!</p>
                <form id="form" onSubmit={submitForm}>
                    <input type="hidden" name="access_key" value={constants.EMAIL_ACCESS_KEY} />
                    <input type="hidden" name="subject" value="New Lead Interested in Real Estate Reports" />


                    <div className="form-item">
                        <label className="form-label">Name*</label>
                        <input required className="form-input" name="Name" placeholder="Full Name"></input>
                    </div>
                    <div className="form-item">
                        <label className="form-label">Email*</label>
                        <input required className="form-input" name="Email Address" placeholder="Email Address"></input>
                    </div>
                    <div className="form-item">
                        <label className="form-label">I am a*</label>
                        <select required className="form-input" name="Client Type">
                            <option value={''}>Please Select</option>
                            <option value={'Buyer'}>Buyer</option>
                            <option value={'Seller'}>Seller</option>
                            <option value={'Renter'}>Renter</option>
                            <option value={'Other'}>Other</option>

                        </select>
                    </div>
                    <div className='form-item'>
                        <label className="form-label">I am interested in:</label>
                        <div className='grid grid-cols-2 gap-3 mt-3'>
                            <div className='cursor-pointer'>
                                <input className='mr-2' name="Interested in:" value={'Buying guides'} type="checkbox" id="1"/><label htmlFor="1">Guides for Buying</label>
                            </div>
                            <div>
                                <input className='mr-2' name="Interested in:" value={'Selling guides'} type="checkbox" id="2"/><label htmlFor="2">Guides for Selling</label>
                            </div>
                            <div>
                                <input className='mr-2' name="Interested in:" value={'Choosing realtor'} type="checkbox" id="3"/><label htmlFor="3">Choosing a Realtor</label>
                            </div>
                            <div>
                                <input className='mr-2' name="Interested in:" value={'Renovations'} type="checkbox" id="4"/><label htmlFor="4">Home Renovations</label>
                            </div>
                        </div>


                    </div>
                    <button type='submit' className="btn-primary self-start rounded-none px-10 mt-3">Submit</button>

                </form>
            </div>
            <div className="overflow-hidden h-[600px] ">
                <img className="h-full w-full object-cover" src={img} />
            </div>
        </div>
        {popup &&
                <>
                    <div className="backdrop" />
                    <div className="popup">
                        <h5 className="font-bold text-black text-lg">{popup.subject}</h5>
                        <p className="font-semibold text-med-grey tracking-wide mb-3">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}
        </>
    )
}