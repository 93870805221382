import React, { useState, useEffect, useRef } from "react";
import bannerImg from '../assets/images/House5.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faEnvelope, faPhone, faStar } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import avatar1 from "../assets/images/avatar1.png"
import avatar2 from "../assets/images/avatar3.png"
import placeholder from "../assets/images/house-placeholder.webp"
import { Link } from "react-router-dom";
import imgs from "./temp.json"
import ReactImageGallery from "react-image-gallery";
import calc from "../assets/images/stock-photo-close-up-woman-hand-using-calculator-to-calculate-home-finance-and-writing-note-accounting-on-2489188913.jpg"
import report from "../assets/images/stock-photo-businesswoman-holding-smartphone-while-working-with-laptop-at-home-office-desk-using-cross-699442570.jpg"
import house from "../assets/images/stock-photo-modern-minimalist-two-story-house-with-a-flat-roof-and-large-glass-windows-featuring-a-sleek-2493365641.jpg"
import constants from '../constants/constants'


export default function Homepage() {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/"
    const testimonials = [{ quote: 'He is really good sds sf sdf sfsdf sdfsf sdfsdf sf sdfs dfs fsd fsfd fsd fsdf sdfsfds sdfs fsf sdfs dfsfdsdf sdfdsfs fsdffsd sdfsdfsf sdfsdf sdfsfsdfsdf sdfsdf sdfsdfsdf sdfsdfsdf', name: 'Larry Ross', image: avatar1 },
    { quote: 'Best realtor ever', name: 'Sylvia Jones', image: avatar2 }]

    const [listings, setListings] = useState([])
    const [media, setMedia] = useState(imgs.thumbs.value)
    const [formatted, setFormatted] = useState({})
    const loaded = useRef(false)
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    function getData() {
        // for development
        var body = {

            "url": "https://query.ampre.ca/odata/Property?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=12",
            // "url": `https://query.ampre.ca/odata/Property?$filter=ListAgentKey eq '${constants.AGENT_ID}' or CoListAgentKey eq '${constants.AGENT_ID}'`,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }
        return new Promise(async (resolve, reject) => {
            try {
                // this is for production
                // fetch(`${proxyUrl}https://query.ampre.ca/odata/Property?$filter=ListAgentKey eq \'${constants.AGENT_ID}\' or CoListAgentKey eq \'${constants.AGENT_ID}\'&orderby=OriginalEntryTimestamp desc,ListingKey&$top=12`, {


                // let request = `odata/Property?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=12`
                let request = `odata/Property(Q)$orderby=OriginalEntryTimestamp(S)desc,ListingKey(A)$top=12`


                let data = constants.ENVRIONMENT === 'prod' ? await fetch(`https://uvhomes.aptoconnect.com/api/${request}`)
                    : await fetch(`http://${constants.URL}/metaGet`, {
                        method: 'POST',
                        body: JSON.stringify(body),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }


                // this is for development
                // let data = await fetch(`http://${constants.URL}/metaGet`, {
                //     method: 'POST',
                //     body: JSON.stringify(body),
                //     headers: {
                //         "Content-Type": "application/json"
                //     }

                // })



                if (data.ok) {
                    let res = await data.json()
                    console.log(res)
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    function getMedia(listings) {

        // this is for development
        //var temp = `https://query.ampre.ca/odata/Media?$filter=(`




        // this is for production
        // var temp = `odata/Media?$filter=(`


        if (constants.ENVRIONMENT === 'local') {
            var temp = `https://query.ampre.ca/odata/Media?$filter=ResourceRecordKey in (`
            for (let l of listings) {
                temp = temp + '\'' + l.ListingKey + '\', '

            }
            temp = temp.slice(0, -2)
            temp = temp + ') and ImageSizeDescription eq \'Thumbnail\' and PreferredPhotoYN eq true'
        } else {

            var temp = `odata/Media(Q)$filter=ResourceRecordKey(S)in(S)(`
            for (let l of listings) {
                temp = temp + '\'' + l.ListingKey + '\','

            }
            temp = temp.slice(0, -1)
            temp = temp + ')(S)and(S)ImageSizeDescription(S)eq(S)\'Thumbnail\'(S)and(S)PreferredPhotoYN(S)eq(S)true'
         
        }
        var body = {

            "url": temp
            ,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }

        return new Promise(async (resolve, reject) => {
            try {

                // this is for production
                let data = constants.ENVRIONMENT === 'prod' ? await fetch(`https://uvhomes.aptoconnect.com/api/${temp}`)
                : await fetch(`http://${constants.URL}/metaGet`, {
                   method: 'POST',
                   body: JSON.stringify(body),
                   headers: {
                       "Content-Type": "application/json"
                   }
               })
                
                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }

                // this is for development
                // let data = await fetch(`http://${constants.URL}/metaGet`, {
                //     method: 'POST',
                //     body: JSON.stringify(body),
                //     headers: {
                //         "Content-Type": "application/json"
                //     }

                // })


                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    function formatMedia(media) {
        var temp = {}

        for (let img of media) {
            if (temp[img.ResourceRecordKey]) {
                temp[img.ResourceRecordKey] = [...temp[img.ResourceRecordKey], img]
            } else {
                temp[img.ResourceRecordKey] = [img]

            }
        }
        return temp
    }

    useEffect(() => {

        if (!loaded.current) {
            var temp1 = []
            getData().then(data => {
                // data.value[0].ListingKey = 'W9297050';
                setListings(data.value)
                getMedia(data.value).then(d => {
                    setMedia(d.value)
                    temp1 = d.value
                    setFormatted(formatMedia(d.value))


                }).catch(err => {
                    console.log(err);
                })


            }).catch(err => {
                console.log(err);
            })



            // getMedia().then(data => {
            //     console.log(data)
            //     setMedia(data.value)


            // }).catch(err => {
            //     console.log(err);
            // })
            // var temp = {}

            // for (let img of temp1) {
            //     if (temp[img.ResourceRecordKey]) {
            //         temp[img.ResourceRecordKey] = [...temp[img.ResourceRecordKey], img]
            //     } else {
            //         temp[img.ResourceRecordKey] = [img]

            //     }
            // }
            // console.log(temp)
            // setFormatted(temp);
            loaded.current = true;
        }

    }, [])



    return (
        <>
            <div className="w-full bg-no-repeat bg-cover min-h-[90vh] relative" style={{ backgroundImage: `url(${bannerImg})` }}>
                <div className="p-12 bg-logo-main w-min absolute top-[30%] left-[10%]">
                    <h1 className="text-white text-3xl sm:text-5xl font-mont">NICK </h1>
                    <h1 className="text-white text-3xl sm:text-5xl font-mont">LASTNAME</h1>
                    <h4 className="text-white text-xl font-mont mt-5 whitespace-normal">Real Estate Agent, TREB</h4>

                </div>

            </div>
            <h1 className="text-4xl text-black py-8 font-mont text-center mt-6">AWARDS</h1>
            <div className="grid mx-auto sm:grid-cols-3 w-fit gap-x-12 gap-y-12 child:max-w-64 child:flex child:flex-col child:gap-y-5 child:text-center ">
                <div>
                    <FontAwesomeIcon icon={faAward} className="fa-5x text-logo-second " />
                    <span className="text-logo-second font-semibold text-2xl">2022</span>
                    <span className="text-black font-semibold text-xl">Best Realtor Toronto</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faStar} className="fa-5x text-logo-second" />
                    <span className="text-logo-second font-semibold text-2xl">2023</span>
                    <span className="text-black font-semibold text-xl">Most Houses Sold</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faAward} className="fa-5x text-logo-second" />
                    <span className="text-logo-second font-semibold text-2xl">2019</span>
                    <span className="text-black font-semibold text-xl">Favourite Realtor </span>
                </div>




            </div>
            <div className="bg-logo-second p-20 mx-auto mt-16 flex flex-col md:flex-row gap-y-10 gap-x-14 max-w-[900px]">
                <img src={constants.HEADSHOT} className="min-w-52" />
                <div>
                    <h1 className="font-mont text-white text-2xl italic">"Quote fdv dfvd vdfv sfsdf dfvd vdv dfv dfvd vfdvd vfdvdvdf vdfvdv vdvdv dvdvd vdvd"</h1>
                    <p className="text-white mt-3 text-xl font-mont font-semibold">Nick Lastname</p>
                    <p className="text-white mt-1 text-lg font-mont">TREB Reality</p>
                </div>



            </div>
            <h1 className="text-4xl text-black py-8 font-mont text-center mt-6">TESTIMONIALS</h1>

            <div className='mx-auto max-w-[800px] px-[15%] sm:px-[10%] xl:px-[5%] h-[45vh] sm:h-[35vh]' >

                <Swiper
                    style={{ "--swiper-pagination-color": "rgb(71, 71, 71)" }}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    loopPreventsSliding={true}
                    // autoplay={{
                    //     delay: 10000,
                    //     disableOnInteraction: true,
                    // }}
                    modules={[Pagination, Autoplay]}

                >

                    {
                        testimonials?.map((t, index) =>
                            <SwiperSlide key={index} >
                                <div className='swipe-slide h-[45vh] sm:h-[35vh]' >
                                    {/* <div style={{overflowClipMargin: 'unset'}} className="rounded-full w-20 h-20 overflow-clip">
                                        <img className='w-full h-full object-cover' src={t.image} />
                                    </div> */}
                                    <img className='w-20' src={t.image} />
                                    <span className="text-black font-bold text-2xl">{t.name}</span>

                                    <span className="font-semibold text-med-grey text-lg italic">"{t.quote}"</span>
                                </div>
                            </SwiperSlide>


                        )
                    }

                </Swiper>
            </div>
            <h1 className="text-4xl text-black py-8 font-mont text-center mt-6">RECENT LISTINGS</h1>
            <div className="grid listing-grid gap-9 max-w-[1100px] mx-auto px-7">
                {listings?.map((prop) =>
                    <Link className="w-full h-full" to={`listing/${prop?.ListingKey}`}>
                        <div className="relative w-full aspect-[5/3] sm:aspect-[4/3] overflow-hidden">
                            <img className="w-full h-full object-cover" src={formatted[prop?.ListingKey]?.[0]?.MediaURL ?? placeholder} />
                            <div className="px-2 py-1 bg-logo-second absolute bottom-0 w-full max-h-[56px]">
                                <span className=" text-white text-sm font-mont font-semibold">{prop?.CrossStreet}</span>
                            </div>
                        </div>
                        {/* <ReactImageGallery items={formatted[prop.ListingKey] ?? [{original: placeholder, thumbnail: placeholder}]} 
                        showThumbnails={false}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        />
                        <div className="px-2 py-1 bg-logo-second  bottom-0 w-full z-10">
                                <span className=" text-white text-sm font-mont font-semibold">{prop.CrossStreet}</span>
                            </div> */}
                    </Link>

                )}

            </div>
            <h1 className="text-4xl text-black py-8 font-mont text-center mt-6">USEFUL TOOLS</h1>
            <div className="grid md:grid-cols-3 gap-10 child:overflow-hidden max-w-[1100px] mx-auto px-7 mb-10">
                <Link to={'/calculator'}>
                    <div className="overflow-hidden h-96">
                        <img className="h-full w-full object-cover" src={calc} />
                    </div>
                    <h3 className="text-2xl text-black pt-5 pb-2 font-mont">Mortgage Calculator</h3>
                    <span className="font-mont">Find a price range that works with your budget by using our Mortgage Calculator</span>
                </Link>
                <Link>
                    <div className="overflow-hidden h-96">
                        <img className="h-full w-full object-cover" src={report} />
                    </div>
                    <h3 className="text-2xl text-black pt-5 pb-2 font-mont">Real Estate Reports</h3>
                    <span className="font-mont">Gain valuable insights into the market with our free Real Estate Reports</span>
                </Link>
                <Link to={'/'}>
                    <div className="overflow-hidden h-96">
                        <img className="h-full w-full object-cover" src={house} />
                    </div>
                    <h3 className="text-2xl text-black pt-5 pb-2 font-mont">Home Evaluation</h3>
                    <span className="font-mont">How much is your home worth in today's market? Find out with our free Home Evaluation</span>
                </Link>

            </div>

            <div className="w-full bg-light-grey flex flex-wrap gap-x-16 gap-y-10 justify-center py-16 px-7">
                <div>
                    <h4 className="font-mont font-semibold text-black text-lg min-w-[20vw]">Questions? I'm Here To Help</h4>
                    <form className="mt-6 flex flex-col">
                        <div className="form-item">
                            <label className="form-label">Name*</label>
                            <input className="form-input" placeholder="Full Name"></input>
                        </div>
                        <div className="form-item">
                            <label className="form-label">Email*</label>
                            <input className="form-input" placeholder="Email Address"></input>
                        </div>
                        <div className="form-item">
                            <label className="form-label">Message*</label>
                            <textarea className="form-input resize-none h-32" placeholder="Your Message"></textarea>
                        </div>
                        <button className="btn-primary self-start rounded-none px-10">Send</button>
                    </form>
                </div>
                <div>
                    <iframe class="contact-map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.1670922064877!2d-79.3108446!3d43.686289499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cc14ec855631%3A0xb3d0feb68a2d0ed3!2s85%20Cedarvale%20Ave%2C%20Toronto%2C%20ON%20M4C%204J7!5e0!3m2!1sen!2sca!4v1730912488107!5m2!1sen!2sca"
                        height="365" width={`${windowSize > 900 ? '500' : '300'}`} style={{ border: 0 }} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>

                    <div className="flex flex-col gap-y-2 text-black mt-6">
                        <h2 className="text-black text-2xl">{constants.AGENT_NAME}</h2>
                        <p className="italic">{constants.AGENT_TITLE}, {constants.BROKERAGE}</p>
                        <p><FontAwesomeIcon icon={faPhone} className="text-blue-800 fa-lg" /> {constants.AGENT_NUMBER} </p>
                        <p><FontAwesomeIcon icon={faEnvelope} className="text-blue-800 fa-lg" /> {constants.AGENT_EMAIL} </p>
                    </div>
                </div>

            </div>






        </>
    )
}